/* You can add global styles to this file, and also import other style files */

html,
body
  min-height: 100%


.content-container
  min-height: calc(100vh - 186px)

.selectedTeam
  min-height: 400px
  display: flex
  flex-direction: column
  justify-content: flex-end
  background-image: var(--bgImage)
  background-repeat: no-repeat
  background-position: 50% 10%
  background-size: cover

.sidebar
  height: 400px
  display: inline-block
  width: 85px
  .card-title
    position: absolute
    bottom 0
    left: 0
    padding: 1rem
    margin-top: 1rem
    margin-bottom: -5rem
    transform: rotate(270deg)
    min-width: 400px
    background-color: transparent
    transform-origin: 0 0

.gameDetails
  background-image: var(--bg)
  background-repeat: no-repeat
  background-size: cover
  background-position: center

  .bg-dark
      background-color: #343a40de!important

.POR
  background-position: 5%
  transform: scaleX(-1)
  .team-left
    transform: scaleX(-1)
    left: calc(100% - 120px)!important

.WBD
  background-position: 20% 10%
  background-size: 200%

.BRI
  background-position: 45% 30%
  background-size: 200%
